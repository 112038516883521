<template>
  <el-container>
    <nav-main></nav-main>
    <el-main>
      <div class="bannerbox">
        <h2 class="tittleh2">常见问题</h2>
        <h2 class="tittleh3">Common problem</h2>
      </div>
      <div class="centerbox">
        <ul class="lefttit">
          <li
            v-for="(item,index)  in queData"
            :class="activeIndex==item.Id &&'active'"
            @click="handelTab(item.Id)"
          >{{item.tit}}</li>
        </ul>
        <div class="rightbox">
          <p v-for="(item,index) in queData" v-if="activeIndex==item.Id" v-html="item.desc"></p>
        </div>
      </div>
    </el-main>
     <footer-all></footer-all>
  </el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
export default {
  components: {
    NavMain,
    FooterAll
  },
  created() {
    if (this.id) {
      this.activeIndex = this.id;
    }
  },
  data() {
    return {
      activeIndex: 1,
      queData: [
        {
          Id: 1,
          tit: "豆豆记介绍",
          desc:
            '<p style="line-height: 1.5;"><span style="font-size: 12pt;"><strong>豆豆记，专业知识创作社区。</strong></span></p><p style="line-height: 1.5;">致力于为个人或团队，提供在线图书、专栏等的知识管理及创作，打造轻松简单的知识体系。</p><p style="line-height: 1.5;">主要功能包括：<br />1. 团队协作，相同知识领域、技术爱好的成员、通过默契的协作，让彼此的知识实现最大的价值；<br />2. 图书，熟悉的领域、专业的知识、通过图书、让知识大放异彩、服务大众；<br />3. 专栏，为一些在某领域有深度研究的小伙伴提供写作专栏。</p><pre style="line-height: 1.5;"><span style="font-size: 12pt;">特色功能：<br />1. 团队多人协作，提升协作效率。</span><br /><span style="font-size: 12pt;">2. 主流PC操作系统全覆盖，您可以根据需要自由选择豆豆记的Windows、Mac、Ubuntu、UOS、deepin系统客户端。</span><br /><span style="font-size: 12pt;">3. 动态切换编辑器，您可以根据需要自由在普通富文本编辑器和Markdown编辑器之间来回切换。<br /><br /><img style="float: left;" src="https://xapi.beanotes.com/upload/images/normal/2020/November/1606231718708.png" width="760" /><br /></span></pre>',
        },
        {
          Id: 2,
          tit: "笔记功能说明",
          desc:
            '<p>豆豆记客户端内置了笔记功能，用户可以自由选择富文本编辑器和Markdown编辑器。所有笔记均免费保存到云端。此外用户笔记不对外开放，仅作者本人能查看。</p><p>为方便程序员朋友的使用，笔记功能添加了代码语法高亮组件，可以更美观的显示代码了。</p>',
        },
        {
          Id: 3,
          tit: "团队、图书、专栏",
          desc:
             '<p>豆豆记所有的图书、专栏及专栏文章保存后仅作者本人能查看，只有将其发布审核后才能对外访问。</p><p>团队， 团队是为方便多人协作而创建的，用户可以创建多个团队，每个团队下可以自由添加图书、专栏等。默认团队创建人即为团队负责人，负责团队的管理、人员的审核、订单管理、团队收益及分配。在团队设置中可以重新指定团队负责人。</p><p>您需要注意的是：对于加入团队的申请，请谨慎处理！因为团队下的所有成员均可编辑、修改团队下的图书、专栏文章。</p>',
        },
        {
          Id: 4,
          tit: "订单说明",
          desc:
            '<p>豆豆记的订单交易列表，请前往web端个人中心的查看。交易列表能查看到账户当前的余额、交易记录、提现列表等。</p><p>用户在提现前需要在个人中心绑定手机号及银行卡，平台在收到提现请求后三个工作日内进行审核。</p><p>如果您在支付、提现等环节遇到的问题，欢迎随时联系我们。</p>' 
        },
        {
          Id: 5,
          tit: "分成及奖励",
          desc:
            '<p><strong>所有在豆豆记上发布的图书、专栏，版权归作者所有。</strong></p><p>平台会对图书、专栏的销售收入收取一定比例（20%）的服务费, 该服务费在订单支付时扣除。创作人实际的收入为销售价格的80%。</p><p>举个例子，如果一本书定价36元，销售1000本，那么创作者的实际收入是：</p><p>36*1000*80%= 28800 元</p><p>为鼓励用户创作更多的原创的、实用的图书、专栏作品。平台将不定期对优秀的创作人进行奖励。</p>',
        },
      ],
    };
  },
  methods: {
    handelTab: function (id) {
      this.activeIndex = id;
    },
  },
  computed: {
    id() {
      return this.$route.query.id || ""; //当前项目id
    },
  },
};
</script>
<style lang="less" scoped>
.el-container {
  flex-direction: column;
  // height: 100%;
  .el-container {
    // height: e("calc(100% - 70px)");
  }
  .el-main {
    padding: 0;
  }
}
.bannerbox {
  height: 90px;
  background: url("../../assets/imgs/webimg/questionbanner.png") no-repeat;
  background-size: cover;
  padding-top: 25px;
  box-sizing: border-box;
  .tittleh2,
  .tittleh3 {
    width: 1200px;
    box-sizing: border-box;
    padding-left: 80px;
    margin: 0 auto;
    font-size: 18px;
    color: #2f95e3;
  }
  .tittleh3 {
    font-size: 14px;
  }
}
.centerbox {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 30px 0;
  box-sizing: border-box;
  .lefttit {
    width: 260px;
    padding: 0 30px 0 80px;
    box-sizing: border-box;
    border-right: 1px solid #e2e2e2;
    li {
      line-height: 40px;
      font-size: 15px;
      cursor: pointer;
      &.active {
        color: #23b7e5;
      }
      &:hover {
        color: #23b7e5;
      }
    }
  }
  .rightbox {
    flex: 1;
    min-height: 300px;
    padding: 10px 30px;
    line-height: 30px;
    color: #666;
    font-size: 14px;
  }
}
</style>